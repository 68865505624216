import React from 'react';
import emailjs from '@emailjs/browser';
import { goodsData } from "../goods-data";
import { Navigate } from "react-router-dom";

function Item(props) {
  const item = props.item;
  const itemImg = `/images/goods/${item.img}.jpg`;
  return (
    <li>
      <div className="item-list__item-content">
        <div className="item-list__item-img">
          <img src={itemImg} alt={item.name} />
        </div>
        <div className="item-list__item-name">{item.name}</div>
        <div className="item-list__item-info">{item.price} 円 / {item.spec}</div>
      </div>
      <div className="item-list__item-quantity">
        <input type="number" min="0" step="1" value={item.quantity} onChange={props.onChange} />
      </div>
    </li>
  );
};

class Purchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: goodsData.map((obj, i) => ({ ...obj, quantity: 0, index: i })),
      cart: [],
      sent: false,
      error: false,
    }
    this.updateQuantity = this.updateQuantity.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }
  updateQuantity(i, e) {
    let newData = this.state.data;
    e.target.value = Math.round(e.target.value);
    newData[i].quantity = e.target.value;
    this.setState({
      data: newData,
      cart: this.state.data.filter((item) => item.quantity > 0),
    });
  }
  renderItems(type) {
    const items = this.state.data.filter((item) => item.type.includes(type));
    return items.map((item, key) => {
      return (
        <Item key={key} item={item} onChange = {(e) => this.updateQuantity(item.index, e)} />
      );
    });
  }
  renderCartItems() {
    return this.state.cart.map((item, key) => {
      return (
        <tr key={key}>
          <td align="left" style={{padding: "5px" }}>{item.name}</td>
          <td align="right" style={{padding: "5px" }}>{item.price} 円</td>
          <td align="center" style={{padding: "5px" }}>×</td>
          <td align="right" style={{padding: "5px" }}>{item.quantity}</td>
          <td align="center" style={{padding: "5px" }}>=</td>
          <td align="right" style={{padding: "5px" }}>{item.price * item.quantity} 円</td>
        </tr>
      );
    });
  }
  renderCartTotal() {
    let total = 0;
    this.state.cart.forEach((item) => {
      total += item.price * item.quantity;
    })
    return (
      <tr>
        <td colSpan="5" align="left" style={{borderTop: "#ccc 1px solid", padding: "5px"}}>
          合計（送料別）
        </td>
        <td align="right" style={{borderTop: "#ccc 1px solid", padding: "5px"}}>
          {total} 円
        </td>
      </tr>
    );
  }
  renderCartList() {
    if (this.state.cart.length > 0) {
      return (
        <table id="shopping-list" width="100%" border="0" cellSpacing="0" cellPadding="0">
          <tbody>
            {this.renderCartItems()}
          </tbody>
          <tfoot>
            {this.renderCartTotal()}
          </tfoot>
        </table>
      )
    }
    else {
      return (
        <div className="no-shipping-list">
          選択した商品がありません
        </div>
      )
    }
  }

  sendEmail(e) {
    e.preventDefault();
    document.getElementById("submit-button").innerHTML = "<div class='loading'><span>Loading</span></div>";
    document.getElementById("submit-button").disabled = true;
    const tableContent = document.getElementById("shopping-list").outerHTML;
    document.getElementById("list-in-mail").value = tableContent;
    emailjs.sendForm("service_3yyihen", "template_f8f8ocb", "#purchase-form", "WZCFCSBFxIGfUfSz9")
      .then((result) => {
          console.log(result.text);
          this.setState({
            sent: true
          });  
      }, (error) => {
          console.log(error.text);
          this.setState({
            error: true
          });
      });
  }

  render() {
    if (this.state.sent) {
      return <Navigate to={"/purchase-sent"}/>
    }
    else if (this.state.error) {
      return <Navigate to={"/contact-sent-error"}/>
    }
    return (
      <>
        <h2 className="page-title">
          <i className="fa fa-fw fa-leaf"></i>
          商品購入
        </h2>
        <div className="highlight-block">
          <h3 className="page-subtitle">お客様及びあっぷるみんと会員様へお願いとお知らせ</h3>
          <p>本年度のご注文ありがとうございました。令和6年産のきよら米は締切ました。10月より新米の受付を再開します。</p>
        </div>
        <div className="normal-block">
          <h3 className="page-subtitle">購入について</h3>
          <ul className="info">
            <li><h4 className="has-icon"><i className="fa fa-fw fa-tint"></i>個人メールを使ってます。連絡、返信に時間がかかります。ご承知ください。</h4></li>
            <li><h4 className="has-icon"><i className="fa fa-fw fa-tint"></i>商品金額決定後口座振り込みをお願いします。</h4></li>
            <li><h4 className="has-icon"><i className="fa fa-fw fa-tint"></i>商品価格は、すべて税込みです。</h4></li>
            <li><h4 className="has-icon"><i className="fa fa-fw fa-tint"></i>会員の方は明記してください。商品代金を一割引きとさせていただきます。</h4></li>
            <li><h4 className="has-icon"><i className="fa fa-fw fa-tint"></i>わからない点がございましたら連絡事項欄にお問い合わせください。</h4></li>
          </ul>
        </div>
        <div className="normal-block">
          <h3 className="page-subtitle">農産物</h3>
          <ul className="item-list">
            {this.renderItems("produce")}
          </ul>
        </div>
        <div className="normal-block">
          <h3 className="page-subtitle">加工品</h3>
          <ul className="item-list">
            {this.renderItems("processed")}
          </ul>
        </div>
        <div className="normal-block">
          <h3 className="page-subtitle">酒類</h3>
          <ul className="item-list">
            {this.renderItems("alcohol")}
          </ul>
        </div>

        <form id="purchase-form" onSubmit={this.sendEmail} className="purchase-info">
          <div className="selected-info">
            <h3>ご選択の商品は：</h3>
            {this.renderCartList()}
          </div>
          <div className="shipping-info">
            <h3>送料について</h3>
              <div className="wide-only">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>九州</th>
                      <th>四国・関西まで</th>
                      <th>北陸・東海</th>
                      <th>関東</th>
                      <th>東北</th>
                      <th>北海道</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th className="left">5kg以内<br />（60サイズ）</th>
                      <td>930円</td>
                      <td>1040円</td>
                      <td>1150円</td>
                      <td>1370円</td>
                      <td>1590円</td>
                      <td>2030円</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="mobi-only">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>5kg以内<br />（60サイズ）</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>九州</th>
                      <td>930円</td>
                    </tr>
                    <tr>
                      <th>四国・関西まで</th>
                      <td>1040円</td>
                    </tr>
                    <tr>
                      <th>北陸・東海</th>
                      <td>1150円</td>
                    </tr>
                    <tr>
                      <th>関東</th>
                      <td>1370円</td>
                    </tr>
                    <tr>
                      <th>東北</th>
                      <td>1590円</td>
                    </tr>
                    <tr>
                      <th>北海道</th>
                      <td>2030円</td>
                    </tr>
                </tbody>
              </table>
            </div>
            <div className="shipping-fee">
              <h4 className="has-icon"><i className="fa fa-tint"></i>以上は参考価格です。重さ、箱のサイズ（たて * 横 * 高さ）によって料金がかかります。</h4>
              <h4 className="has-icon"><i className="fa fa-tint"></i>別途消費税（10%）がかかります。</h4>
              <h4 className="has-icon"><i className="fa fa-tint"></i>クール便は別途料金がかかります。</h4>
              <h4 className="has-icon"><i className="fa fa-tint"></i>メール便は、全国 360 円（ハーブティー、ソルト、４袋まで）</h4>
              <h4 className="has-icon"><i className="fa fa-tint"></i>くわしくは、返信メールで明記します。</h4>
            </div>
          </div>
          <div className="user-info">
            <div className="control-group">
              <label className="control-label">
                <span className="required">*</span>メールアドレス
              </label>
              <div className="controls">
                <input type="email" name="reply_to" className="full" required />
              </div>
            </div>
            <div className="control-group">
              <label className="control-label">
                <span className="required">*</span>お名前
              </label>
              <div className="controls">
                <input type="text" name="name" required />
              </div>
            </div>
            <div className="control-group">
              <label className="control-label">
                <span className="required">*</span>電話番号
              </label>
              <div className="controls">
                <input type="text" name="tel" required />
              </div>
            </div>
            <div className="control-group">
              <label className="control-label">
                <span className="required">*</span>お届け先郵便番号
              </label>
              <div className="controls">
                <input type="text" className="short" name="postcode1" required />
                -
                <input type="text" className="short" name="postcode2" required />
              </div>
            </div>
            <div className="control-group">
              <label className="control-label">
                <span className="required">*</span>お届け先住所
              </label>
              <div className="controls">
                <input type="text" name="address" className="full" required onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }} />
              </div>
            </div>
            <div className="control-group">
              <label className="control-label">
                他の連絡事項
              </label>
              <div className="controls">
                <textarea name="message" className="full" />
              </div>
            </div>
          </div>
          <div className="actions">
            <input type="hidden" id="list-in-mail" name="list" />
            <button id="submit-button" type="submit" className="btn">送信</button>
          </div>
        </form>
      </>
    );
  }
}

export default Purchase;

/*
        <div className="highlight-block">
          <h3 className="page-subtitle">お客様及びあっぷるみんと会員様へお願いとお知らせ</h3>
          <p>平素よりあっぷるみんとの商品をお買い上げいただき、また自然食レストラン風のもりをご利用いただきありがとうございます。</p>
          <p>いままでご愛顧いただきました皆様に、少しでもご利用いただきやすい価格を提供したいと努力してまいりましたが、ここ近年仕入れ資材や材料代、配送料などの相次ぐ高騰を受け、価格の維持が厳しい状態となりました。</p>
          <p>物価高騰の厳しい現在、大変心苦しくはありますが、４月１日よりあっぷるみんと全商品を値上げさせていただく事としました。</p>
          <p>ただし、一部農産物や、風のもりのメニュー価格は据え置きとさせていただきます。お客様にはご迷惑をおかけしますが、何卒ご理解いただきますようお願い致します。</p>
          <footer>あっぷるみんとハーブ農園・自然食レストラン風のもり</footer>
        </div>
*/