import { Outlet } from "react-router-dom";
import { ScrollRestoration } from "react-router-dom";
import PageHeader from "../partials/page-header";
import PageFooter from "../partials/page-footer";
import { useLocation } from 'react-router-dom';

export default function Root() {
  const location = useLocation();
  const showNote  = (location.pathname === "/" || location.pathname === "/restaurant")

  return (
    <>
      <div className="page-wrapper">
        <PageHeader />
        <div className="main-wrap">
          <div className="container">
            <div className="shop-info">
              <ul className="message">
                <li><i className="fa fa-fw fa-clock-o"></i><span>営業時間：11:00 ~ 17:00</span></li>
                <li><i className="fa fa-fw fa-calendar"></i><span>定休日：月曜日・火曜日・金曜日・不定休</span></li>
                <li><i className="fa fa-fw fa-info-circle"></i><span>ランチは終わり次第終了いたします。</span></li>
                <li><i className="fa fa-fw fa-info-circle"></i><span>土曜日日曜日、祝日、および大型連休などはご予約をお受けできません。</span></li>
              </ul>
            </div>
            <Outlet />
          </div>
        </div>
      </div>
      <PageFooter />
      <ScrollRestoration />
    </>
  );
}

/* 

            {
              showNote && 
              <div className="highlight-block">
                <h3 className="page-subtitle">メニュー変更に関するお知らせ</h3>
                <p>ホールスタッフ不足により、従来のメニューやそれに付随するサービスの提供が難しくなってまいりました。</p>
                <p>検討を重ねた結果、8月よりメニューを風のもりランチ、季節のデザートセットのみのご提供とさせて頂きます。ランチやデザートのお飲み物はドリンクバー形式になります。</p>
                <p>またランチはなくなり次第終了とさせて頂きます。御迷惑、御不便をおかけしますが、何卒ご理解いただきますようお願い致します。</p>
              </div>
            }


新年用
            <div className="shop-info shop-info--important">
              12 月 30 日（月）から 1 月 7 日（火）までお正月休暇をいただきます。1 月 8 日（水）から通常営業となります。
            </div>


            <div className="shop-info shop-info--important">

              8 月 12 日山の日（月）通常営業となります。8 月 14 日（水）お休みをいただきます。
            </div>

GW用
            <div className="shop-info shop-info--important">
              <ul class="ul">
                <li>4月29日昭和の日（月曜日）は営業します。</li>
                <li>ゴールデンウィーク期間5月3日（金曜日）～ 6日（月曜日）は通常営業します。5月7日（火曜日）～ 8日（水曜日）お休みをいただきます。</li>
              </ul>
            </div>

休日用
            <div className="shop-info shop-info--important">
              10 月 14 日スポーツの日（月）通常営業となります。10 月 16 日（水）お休みをいただきます。
            </div>
            <div className="shop-info shop-info--important">
              2 月 11 日建国記念の日（火）、2 月 23 〜 24 日天皇誕生日（日〜月）通常営業となります。
            </div>
做到一半的頁首 modal

            <div className="shop-info">
              <a href="#">お客様及びあっぷるみんと会員様へお願いとお知らせ</a>
            </div>
            <div className="modal">
              <div className="modal_block">
                <button className="modal_close">✕</button>
                <h2>お客様及びあっぷるみんと会員様へお願いとお知らせ</h2>
                <p>平素よりあっぷるみんとの商品をお買い上げいただき、また自然食レストラン風のもりをご利用いただきありがとうございます。</p>
                <p>いままでご愛顧いただきました皆様に、少しでもご利用いただきやすい価格を提供したいと努力してまいりましたが、ここ近年仕入れ資材や材料代、配送料などの相次ぐ高騰を受け、価格の維持が厳しい状態となりました。</p>
                <p>物価高騰の厳しい現在、大変心苦しくはありますが、４月１日よりあっぷるみんと全商品を値上げさせていただく事としました。</p>
                <p>ただし、一部農産物や、風のもりのメニュー価格は据え置きとさせていただきます。お客様にはご迷惑をおかけしますが、何卒ご理解いただきますようお願い致します。</p>
                <footer>あっぷるみんとハーブ農園・自然食レストラン風のもり</footer>
              </div>
            </div>
*/